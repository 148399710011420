import Container from "../components/layout"
import LayoutContainer from "../components/layout-container"
import SEO from "../components/seo"
import React from "react"

// import { responsiveTitle1 } from '../components/typography.module.css'
const browser = typeof window !== "undefined" && window

const NotFoundPage = () => {
  return (
    browser && (
      <LayoutContainer locale="en">
        <SEO title="404: Not found" />
        <Container>
          <h1>Not found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Container>
      </LayoutContainer>
    )
  )
}

export default NotFoundPage
